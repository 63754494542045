import React from 'react'
import { NavLink } from "react-router-dom"

const ContactUs = () => {

    return (

        <>

            <section>
                <div className="container-fluid  d-none d-lg-block d-block " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-none " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div className="container pb-5" >
                    <div className="row d-flex justify-content-center g-2 p-2 FontFamilyMontSerret">
                        <div className='pb-lg-4 mb-4'>
                            <h2 className='text-center' style={{ fontSize: "30px", color: "#212121" }} > Get In Touch </h2>
                            <h6 className='text-center pt-1' style={{ fontSize: "16px", color: "#100e0e", lineHeight: "1.6" }} >Kindly leave your message by filling the below form and we will <br /> get back to you very soon.</h6>
                        </div>
                        <div className='col-lg-5 ' >
                            <div className='p-2'>
                                <div>
                                    <h6 className='pb-2' style={{ fontSize: "25px", fontWeight: "600", color: "#295063" }} >Contact Address</h6>
                                    <p className='pb-3' style={{ fontSize: "16px", lineHeight: "1.6" }} >Call for Taxi for Outstation Tour or for Local <br /> Travel.We have wide range of Vehicles with <br /> us for your service.</p>
                                </div>

                                <div className='mb-3 pb-1'>
                                    <h4 className='pb-2' style={{ fontSize: "20px", fontWeight: "800", color: "#295063", letterSpacing: "1px" }} >Address : </h4>
                                    <h6 className='' style={{ fontSize: "16px", lineHeight: "1.6" }} > iCloudsoft Technology Office No 601, 6th Floor, <br /> Lotus Building, Opp Joshi Railway Museum, <br /> Kothrud, Pune 411038 </h6>
                                </div>

                                <div className='mt-3 pb-2'>
                                    <span className='' style={{ fontSize: "20px", fontWeight: "800", color: "#295063", letterSpacing: "1px" }} >Email :
                                        <NavLink className='text-decoration-none text-dark ' to="mailto:info@soft.co.in">  <span className='ps-1' style={{ fontSize: "17px", fontWeight: "400" }} > info@cloudsoft.co.in </span></NavLink>
                                    </span>
                                </div>

                                <div className='mt-3' >
                                    <span className='' style={{ fontSize: "20px", fontWeight: "800", color: "#295063", letterSpacing: "1px" }} >Mobile No :
                                        <NavLink className='text-decoration-none text-dark ' to="tel:9527468898">  <span className='ps-1' style={{ fontSize: "17px", fontWeight: "400" }} > +91 9527468898, <NavLink className='text-decoration-none text-dark' to='tel:8766779267'><span className='ps-1' style={{ fontSize: "17px", fontWeight: "400" }} > +91 8766779267 </span> </NavLink>  </span> </NavLink>
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div className='col-lg-5  p-0'>
                            <div className='m-0'>
                                <iframe className='w-100 m-0' title="gmap" style={{ height: "420px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d206470.11675755034!2d73.70405255586778!3d18.48319361399898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf285b81dd75%3A0x344ffe59f81b4da1!2siCloudsoft%20Technology!5e0!3m2!1sen!2sin!4v1695706876360!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#ECECEC" }} >
                    <div className="container">
                        <div className="row p-lg-5 ">
                            <div className='text-center mt-2 mb-lg-3 pb-lg-3 p-2'>
                                <h6 className='FontFamilyMontSerret' style={{ fontSize: "25px", color: "#212121", fontWeight: "600" }} >Most Popular Destinations</h6>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-lg-5 col-md-6">
                                <div className='FontFamilyMontSerret' >
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Solapur Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Shirdi Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to kolhapur Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Hyderabad Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Kolhapur</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Sangli</p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6">
                                <div className='FontFamilyMontSerret' >
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Solapur Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Shirdi Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to kolhapur Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Hyderabad Taxi</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Kolhapur</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Sangli</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ContactUs