import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './Header'
import Home from './Home'
import Footer from './Footer'
import OutstationRate from './OutstationRate'
import ContactUs from './ContactUs'
import Mumbai from './Mumbai'
import Shirdi from './Shirdi'
import Mahabaleshwar from './Mahabaleshwar'
import Lonavala from './Lonavala'
import Mahalakshmi from './Mahalakshmi'
import Ashtvinayak from './Ashtvinayak'
import Kolhapur from './Kolhapur'
import Nagpur from './Nagpur'
import Error from './Error'
// import Silder from './Silder'


const Routing = () => {
    return (
        <>

            <React.Fragment>

                <header  >
                    <Header />
                </header>

                <main>
                    <Routes>
                        <Route to path="/" element={<Home />} />
                        <Route to path="/outstationrate" element={<OutstationRate />} />
                        <Route to path="/contactus" element={<ContactUs />} />
                        <Route to path="/mumbai" element={<Mumbai />} />
                        <Route to path="/shirdi" element={<Shirdi />} />
                        <Route to path="/mahabaleshwar" element={<Mahabaleshwar />} />
                        <Route to path="/lonavala" element={<Lonavala />} />
                        <Route to path="/mahalakshmi" element={<Mahalakshmi />} />
                        <Route to path="/ashtvinayak" element={<Ashtvinayak />} />
                        <Route to path="/kolhapur" element={<Kolhapur />} />
                        <Route to path="/nagpur" element={<Nagpur />} />
                        <Route to path="*" element={<Error />} />

                        {/* <Route to path="/silder" element={<Silder />} /> */}
                    </Routes>
                </main>

                <footer>
                    <Footer />
                </footer>

            </React.Fragment>

        </>
    )
}

export default Routing