import React from 'react'
import "./Silder.css"

const Silder = () => {
    return (
        <>

            {/* <div class="container">
                <h1 className='section-header'>
                    Client Review
                    <span>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias dolor repellat voluptatem similique commodi id veritatis, delectus facilis cumque voluptates qui aspernatur ipsum reprehenderit, esse illo accusamus debitis deserunt amet.
                    </span>
                </h1>
                <div className="testimonials">
                    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div className="single-item">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/HCI1.png")} alt="emp1" title='emp-1' />
                                                </div>
                                                <div className="bio">
                                                    <h2> Dave Wood </h2>
                                                    <h4> Web Developer </h4>
                                                </div>
                                            </div>
                                        </div>

                                        <di className="col-md-5">
                                            <div className="content">
                                                <p><span><i className='fa fa-quote-left'></i></span>
                                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis quis ullam accusamus dolor eos saepe minus sequi consequuntur, quisquam, alias odio atque. Fugiat recusandae quaerat optio pariatur expedita ipsum porro?</p>

                                                <p className='socials'>
                                                    <i className='fa fa-twitter'></i>
                                                    <i className='fa fa-behance'></i>
                                                    <i className='fa fa-youtube'></i>
                                                    <i className='fa fa-dribbble'></i>
                                                </p>
                                            </div>
                                        </di>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div className="single-item">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/HCI1.png")} alt="emp1" title='emp-1' />
                                                </div>
                                                <div className="bio">
                                                    <h2> Martin Guptil </h2>
                                                    <h4> Ui/ux Developer </h4>
                                                </div>
                                            </div>
                                        </div>

                                        <di className="col-md-6">
                                            <div className="content">
                                                <p><span><i className='fa fa-quote-left'></i></span>
                                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis quis ullam accusamus dolor eos saepe minus sequi consequuntur, quisquam, alias odio atque. Fugiat recusandae quaerat optio pariatur expedita ipsum porro?</p>

                                                <p className='socials'>
                                                    <i className='fa fa-twitter'></i>
                                                    <i className='fa fa-behance'></i>
                                                    <i className='fa fa-youtube'></i>
                                                    <i className='fa fa-dribbble'></i>
                                                </p>
                                            </div>
                                        </di>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div className="single-item">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/HCI1.png")} alt="emp1" title='emp-1' />
                                                </div>
                                                <div className="bio">
                                                    <h2>  Stephan Jhon </h2>
                                                    <h4> Graphic Developer </h4>
                                                </div>
                                            </div>
                                        </div>

                                        <di className="col-md-6">
                                            <div className="content">
                                                <p><span><i className='fa fa-quote-left'></i></span>
                                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis quis ullam accusamus dolor eos saepe minus sequi consequuntur, quisquam, alias odio atque. Fugiat recusandae quaerat optio pariatur expedita ipsum porro?</p>

                                                <p className='socials'>
                                                    <i className='fa fa-twitter'></i>
                                                    <i className='fa fa-behance'></i>
                                                    <i className='fa fa-youtube'></i>
                                                    <i className='fa fa-dribbble'></i>
                                                </p>
                                            </div>
                                        </di>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div> */}

            <div className="conatiner-fluid FONT d-none d-md-block " style={{ backgroundColor: "#ECECEC" }} >
                <div class="container pt-5 mt-2">
                    <h1 className='section-header  mb-3' style={{ fontSize: "26px" }} >
                        Most Popular Destinations
                        <span className='' >
                            India is one of the most popular tourist destinations in Asia and has been successful in attracting <br /> domestic  and international tourists.
                            India has fascinated many tourists across <br /> the globe with her secularism and her culture.
                        </span>
                    </h1>
                    <div className="testimonials">
                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/solapur1.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Solapur </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Solapur Science Centre </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p >
                                                            <span>
                                                                <i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The place is good for students interested in science and technology. there is a park In front of the science centre. but the maintenance is not good. but still few are working and worth visiting. Even inside many exhibits are not working.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/shirdi.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Shirdi </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Shirdi Sai Baba </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p><span><i className='fa fa-quote-left'></i></span>
                                                            Shri Sai Baba is revered as one of the greatest saints ever seen in India, endowed with unprecedented powers, and is worshipped as a God incarnate. (SAI meaning Sakshaat Ishwar) - GOD THE ABSOLUTE
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center " style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/kolhapur.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Kolhapur </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Shri Mahalaxmi Temple </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} ><span><i className='fa fa-quote-left'></i></span>
                                                            The Shri Mahalaxmi Temple of Kolhapur in Maharashtra, India, is one of the three and half Shakti Peethas listed in various puranas of Hinduism. A Shakti Peetha is a place associated with Shakti, the goddess of power.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/hyadrabad.jpeg")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Hyadrabad </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Charminar </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.3px" }}>
                                                            <span><i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The Charminar is a monument located in Hyderabad, Telangana, India. Constructed in 1591, the landmark is a symbol of Hyderabad and officially incorporated in the emblem of Telangana. The Charminar's long history includes the existence of a mosque on its top floor for more than 425 years.                                                    </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/kolhapur.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Pune </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Shri Ambabai/Mahalaxmi Temple </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} >
                                                            <span>
                                                                <i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The Shri Ambabai/Mahalaxmi Temple of Kolhapur in Maharashtra, India, is one of the three and half Shakti Peethas listed in various puranas of Hinduism. According to these writings, a Shakti Peetha is a place associated with Shakti, the goddess of power.
                                                        </p>

                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/sangali1.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Sangali </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Sangmeshwar Temple </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p><span><i className='fa fa-quote-left'></i></span>
                                                            The Sangameshwar Shiva Temple built by Shri Ram himself is based at the confluence of Krishna and Varana rivers at Haripur in Sangli city. Sri Rama, Lakshmana and Goddess Sita worshiped this idol of Mahadev for a few months when they were in exile and stayed on the banks of the river.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/pune.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Pune </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Shreemant Dagdusheth Ganpati  </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }}>
                                                            <span>
                                                                <i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The Dagadusheth Halwai Ganapati ( Mandir ) temple is a Hindu Temple located in Pune and is dedicated to the Hindu god Ganesh. The temple is visited by over hundred thousand pilgrims every year.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/gao.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Goa </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Palolem Beach </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} >
                                                            <span><i className='fa fa-quote-left'></i></span>
                                                            Palolem Beach ( Canacona ) is a stretch of white sand on a bay in Goa, South India. It's known for its calm waters and for its nightlife, including "silent discos" where partygoers wear headphones.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/bangalore-lalbhag.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Bangalore </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Lalbagh Botanical Garden </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }}><span><i className='fa fa-quote-left'></i></span>
                                                            Lalbagh is one of Bengaluru’s major attractions. A sprawling garden situated in a 240 acres piece of land in the heart of the city, Lalbagh houses India’s largest collection of tropical plants and sub-tropical plants.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/mumbai.jpg")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Pune - Mumbai </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Gateway Of India Mumbai </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "1.1px" }} ><span><i className='fa fa-quote-left'></i></span>
                                                            The Gateway of India is an arch-monument completed in 1924 on the waterfront of Mumbai (Bombay), India. It was  erected to commemorate the landing of King-Emperor George V, the first British monarch to visit India, in December 1911 at Strand Road near Wellington Fountain.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/lonavala.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Lonavala </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} >  Lohagad Fort, Lonavala </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} ><span><i className='fa fa-quote-left'></i></span>
                                                            Another architectural marvel of the Marathas is the Lohagad Fort (Iron Fort) that commands a visit while you are in Lonavala. The hill fort acquired its name because it was considered as an impregnable edifice due to its strategic location at a height of 1050 metres above sea level.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/hyadrabad.jpeg")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Hyadrabad </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Charminar </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }}><span><i className='fa fa-quote-left'></i></span>
                                                            The Charminar is a monument located in Hyderabad, Telangana, India. Constructed in 1591, the landmark is a symbol of Hyderabad and officially incorporated in the emblem of Telangana. The Charminar's long history includes the existence of a mosque on its top floor for more than 425 years.
                                                        </p>

                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div >
            </div>

            <div className="conatiner-fluid FONT d-md-none d-block " style={{ backgroundColor: "#ECECEC" }} >
                <div class="container pt-5 mt-2">
                    <h1 className='section-header  mb-3' style={{ fontSize: "26px" }} >
                        Most Popular Destinations
                        <span className='' >
                            India is one of the most popular tourist destinations in Asia and has been successful in attracting <br /> domestic  and international tourists.
                            India has fascinated many tourists across <br /> the globe with her secularism and her culture.
                        </span>
                    </h1>
                    <div className="testimonials">
                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/solapur1.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Solapur </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Solapur Science Centre </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p >
                                                            <span>
                                                                <i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The place is good for students interested in science and technology. there is a park In front of the science centre. but the maintenance is not good. but still few are working and worth visiting. Even inside many exhibits are not working.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-around" >

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/shirdi.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Shirdi </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Shirdi Sai Baba </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p><span><i className='fa fa-quote-left'></i></span>
                                                            Shri Sai Baba is revered as one of the greatest saints ever seen in India, endowed with unprecedented powers, and is worshipped as a God incarnate. (SAI meaning Sakshaat Ishwar) - GOD THE ABSOLUTE
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center " style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-around" >

                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/kolhapur.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Kolhapur </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Shri Mahalaxmi Temple </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} ><span><i className='fa fa-quote-left'></i></span>
                                                            The Shri Mahalaxmi Temple of Kolhapur in Maharashtra, India, is one of the three and half Shakti Peethas listed in various puranas of Hinduism. A Shakti Peetha is a place associated with Shakti, the goddess of power.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/hyadrabad.jpeg")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Hyadrabad </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Charminar </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.3px" }}>
                                                            <span><i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The Charminar is a monument located in Hyderabad, Telangana, India. Constructed in 1591, the landmark is a symbol of Hyderabad and officially incorporated in the emblem of Telangana. The Charminar's long history includes the existence of a mosque on its top floor for more than 425 years.                                                    </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/kolhapur.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Pune </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Shri Ambabai/Mahalaxmi Temple </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} >
                                                            <span>
                                                                <i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The Shri Ambabai/Mahalaxmi Temple of Kolhapur in Maharashtra, India, is one of the three and half Shakti Peethas listed in various puranas of Hinduism. According to these writings, a Shakti Peetha is a place associated with Shakti, the goddess of power.
                                                        </p>

                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/sangali1.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Sangali </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Sangmeshwar Temple </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p><span><i className='fa fa-quote-left'></i></span>
                                                            The Sangameshwar Shiva Temple built by Shri Ram himself is based at the confluence of Krishna and Varana rivers at Haripur in Sangli city. Sri Rama, Lakshmana and Goddess Sita worshiped this idol of Mahadev for a few months when they were in exile and stayed on the banks of the river.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/pune.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Pune </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Shreemant Dagdusheth Ganpati  </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }}>
                                                            <span>
                                                                <i className='fa fa-quote-left'></i>
                                                            </span>
                                                            The Dagadusheth Halwai Ganapati ( Mandir ) temple is a Hindu Temple located in Pune and is dedicated to the Hindu god Ganesh. The temple is visited by over hundred thousand pilgrims every year.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/gao.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Goa </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Palolem Beach </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} >
                                                            <span><i className='fa fa-quote-left'></i></span>
                                                            Palolem Beach ( Canacona ) is a stretch of white sand on a bay in Goa, South India. It's known for its calm waters and for its nightlife, including "silent discos" where partygoers wear headphones.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/bangalore-lalbhag.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Bangalore </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Lalbagh Botanical Garden </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }}><span><i className='fa fa-quote-left'></i></span>
                                                            Lalbagh is one of Bengaluru’s major attractions. A sprawling garden situated in a 240 acres piece of land in the heart of the city, Lalbagh houses India’s largest collection of tropical plants and sub-tropical plants.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/mumbai.jpg")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Pune - Mumbai </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > Gateway Of India Mumbai </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "1.1px" }} ><span><i className='fa fa-quote-left'></i></span>
                                                            The Gateway of India is an arch-monument completed in 1924 on the waterfront of Mumbai (Bombay), India. It was  erected to commemorate the landing of King-Emperor George V, the first British monarch to visit India, in December 1911 at Strand Road near Wellington Fountain.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/lonavala.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Pune - Lonavala </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} >  Lohagad Fort, Lonavala </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }} ><span><i className='fa fa-quote-left'></i></span>
                                                            Another architectural marvel of the Marathas is the Lohagad Fort (Iron Fort) that commands a visit while you are in Lonavala. The hill fort acquired its name because it was considered as an impregnable edifice due to its strategic location at a height of 1050 metres above sea level.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 w-100 d-flex justify-content-around" >
                                            <div className="col-lg-4 col-md-6">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/hyadrabad.jpeg")} alt="emp1" title='emp-1' />
                                                    </div>
                                                    <div className="bio pt-lg-3">
                                                        <h2 className='mb-2' style={{ fontSize: "20px" }} > Kolhapur - Hyadrabad </h2>
                                                        <h4 className='' style={{ fontSize: "17px" }} > The Charminar </h4>
                                                    </div>
                                                    <div className="content pt-lg-2">
                                                        <p className='' style={{ letterSpacing: "0.8px" }}><span><i className='fa fa-quote-left'></i></span>
                                                            The Charminar is a monument located in Hyderabad, Telangana, India. Constructed in 1591, the landmark is a symbol of Hyderabad and officially incorporated in the emblem of Telangana. The Charminar's long history includes the existence of a mosque on its top floor for more than 425 years.
                                                        </p>

                                                    </div>
                                                    <div>
                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div >
            </div>

        </>
    )
}

export default Silder