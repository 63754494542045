import React from 'react'
import Silder from './Silder'
import { NavLink } from 'react-router-dom'

const OutstationRate = () => {
    return (

        <>

            <section>
                <div className="container-fluid  d-none d-lg-block d-block " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-none " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className='text-center FontFamilyMontSerret'>
                        <h6 style={{ color: "#009688", fontSize: "36px", }} >Taxi Rates in Pune</h6>
                        <h6 style={{ color: "#212121", fontSize: "30px", }} >Taxi Service Rates from Pune to Outstation</h6>
                    </div>
                    <div className="row d-flex justify-content-center p-lg-4">
                        <div className="col-lg-11 ">
                            <div className="row ps-3 mt-4 pb-4 FontFamilyMontSerret d-flex justify-content-center OutstationHOVER p-2" style={{ borderBottom: "1px solid #9e9e9e" }} >
                                <div className="col-lg">
                                    <img className='w-100' src={require("../img/or1.png")} alt="emp1" title='emp-1' />
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3'>
                                        <h6 className='' style={{ fontSize: "18px", fontWeight: "800" }} >Swift Dzire (Sedan)</h6>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='row pt-3' style={{ fontSize: "14px" }} >
                                        <div className='col-lg-4 col-4'>
                                            <img className='w-100 ' src={require("../img/seat.png")} alt="emp1" title='emp-1' />
                                        </div>
                                        <div className='col-lg-8 col-8'>
                                            <h6 className='' style={{ fontSize: "14px", fontWeight: "800" }} >4 seat</h6>
                                            <h6 className='' style={{ fontSize: "85%" }} >4 Person +1 Driver</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3' style={{ fontSize: "14px" }} >
                                        <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 ' style={{ fontSize: "16px", color: "#a76eb1", fontWeight: "800" }} > Rs. per KM </span>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='pt-3 pb-2 ps-lg-4'>
                                        <NavLink className='text-decoration-none' to='https://wa.me/+919527468898?text='><button className='' style={{ backgroundColor: "#009688", border: "1px solid #009688", color: "white", padding: "8px 8px" }} ><b className='p-2' style={{ fontSize: "1rem" }}> Book Now </b></button></NavLink>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-11 ">
                            <div className="row ps-3 mt-4 pb-4 FontFamilyMontSerret d-flex justify-content-center p-2 OutstationHOVER" style={{ borderBottom: "1px solid #9e9e9e" }} >
                                <div className="col-lg">
                                    <img className='w-100' src={require("../img/or2.png")} alt="emp1" title='emp-1' />
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3'>
                                        <h6 className='' style={{ fontSize: "18px", fontWeight: "800" }} >Ertiga (New Modal)</h6>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='row pt-3' style={{ fontSize: "14px" }} >
                                        <div className='col-lg-4 col-4'>
                                            <img className='w-100 ' src={require("../img/seat.png")} alt="emp1" title='emp-1' />
                                        </div>
                                        <div className='col-lg-8 col-8'>
                                            <h6 className='' style={{ fontSize: "14px", fontWeight: "800" }} >6 seat</h6>
                                            <h6 className='' style={{ fontSize: "85%" }} >6 Person +1 Driver</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3' style={{ fontSize: "14px" }} >
                                        <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 ' style={{ fontSize: "16px", color: "#a76eb1", fontWeight: "800" }} > Rs. per KM </span>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='pt-3 pb-2 ps-lg-4'>
                                        <NavLink className='text-decoration-none' to='https://wa.me/+919527468898?text='><button className='' style={{ backgroundColor: "#009688", border: "1px solid #009688", color: "white", padding: "8px 8px" }} ><b className='p-2' style={{ fontSize: "1rem" }}> Book Now </b></button></NavLink>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-11 ">
                            <div className="row ps-3 mt-4 pb-4 FontFamilyMontSerret d-flex justify-content-center OutstationHOVER p-2 " style={{ borderBottom: "1px solid #9e9e9e" }} >
                                <div className="col-lg">
                                    <img className='w-100' src={require("../img/or3.png")} alt="emp1" title='emp-1' />
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3'>
                                        <h6 className='' style={{ fontSize: "18px", fontWeight: "800" }} >Innova Crysta (SUV)</h6>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='row pt-3' style={{ fontSize: "14px" }} >
                                        <div className='col-lg-4 col-4'>
                                            <img className='w-100 ' src={require("../img/seat.png")} alt="emp1" title='emp-1' />
                                        </div>
                                        <div className='col-lg-8 col-8'>
                                            <h6 className='' style={{ fontSize: "14px", fontWeight: "800" }} >6 seat</h6>
                                            <h6 className='' style={{ fontSize: "85%" }} >6 Person +1 Driver</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3' style={{ fontSize: "14px" }} >
                                        <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 ' style={{ fontSize: "16px", color: "#a76eb1", fontWeight: "800" }} > Rs. per KM </span>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='pt-3 pb-2 ps-lg-4'>
                                        <NavLink className='text-decoration-none' to='https://wa.me/+919527468898?text='><button className='' style={{ backgroundColor: "#009688", border: "1px solid #009688", color: "white", padding: "8px 8px" }} ><b className='p-2' style={{ fontSize: "1rem" }}> Book Now </b></button></NavLink>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-11 ">
                            <div className="row ps-3 mt-4 pb-4 FontFamilyMontSerret d-flex justify-content-center p-2 OutstationHOVER " style={{ borderBottom: "1px solid #9e9e9e" }} >
                                <div className="col-lg">
                                    <img className='w-100' src={require("../img/or4.png")} alt="emp1" title='emp-1' />
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3'>
                                        <h6 className='' style={{ fontSize: "18px", fontWeight: "800" }} >Tempo Traveller</h6>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='row pt-3' style={{ fontSize: "14px" }} >
                                        <div className='col-lg-4 col-4'>
                                            <img className='w-100 ' src={require("../img/seat.png")} alt="emp1" title='emp-1' />
                                        </div>
                                        <div className='col-lg-8 col-8'>
                                            <h6 className='' style={{ fontSize: "14px", fontWeight: "800" }} >18 seat</h6>
                                            <h6 className='' style={{ fontSize: "85%" }} >17 Person +1 Driver</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='ps-lg-5 pt-3' style={{ fontSize: "14px" }} >
                                        <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 ' style={{ fontSize: "16px", color: "#a76eb1", fontWeight: "800" }} > Rs. per KM </span>
                                    </div>
                                </div>
                                <div className="col-lg col-6">
                                    <div className='pt-3 pb-2 ps-lg-4'>
                                        <NavLink className='text-decoration-none' to='https://wa.me/+919527468898?text='><button className='' style={{ backgroundColor: "#009688", border: "1px solid #009688", color: "white", padding: "8px 8px" }} ><b className='p-2' style={{ fontSize: "1rem" }}> Book Now </b></button></NavLink>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container pt-4 pb-4 p-lg-5">
                    <div className="row g-4 d-flex justify-content-around p-lg-3">
                        <div className="col-lg-4">

                            <div className='p-4 FontFamilyMontSerret' style={{ backgroundColor: "#fff", boxShadow: "0 0 10px 2px #3F51B5" }} >
                                <h6 className='pb-1' style={{ fontSize: "19px", color: "#212121" }} > Need Experts Help?</h6>
                                <h6 className='pb-2' style={{ fontSize: "12px", color: "#747d7e" }} > WE WOULD BE HAPPY TO HELP YOU!</h6>
                                <NavLink className='text-decoration-none' to='tel:9527468898'><h6 className='pb-1 ps-4' style={{ fontSize: "18px", color: "#4a90a4", letterSpacing: "1px" }} > 9527468898 </h6></NavLink>
                                <NavLink className='text-decoration-none' to='mailto:info@soft.co.in'><h6 className='pb-2 ps-4' style={{ fontSize: "15px", color: "#747d7e", letterSpacing: "1px" }} >info@icloudsoft.com</h6></NavLink>
                            </div>

                        </div>

                        <div className="col-lg-4">
                            <div className='p-4 FontFamilyMontSerret' style={{ backgroundColor: "#fff", boxShadow: "0 0 10px 2px #3F51B5" }} >
                                <h6 className='pb-1' style={{ fontSize: "19px", color: "#212121" }} > Need Experts Help?</h6>
                                <h6 className='pb-2' style={{ fontSize: "12px", color: "#747d7e" }} > WE WOULD BE HAPPY TO HELP YOU!</h6>
                                <NavLink className='text-decoration-none' to='tel:9527468898'><h6 className='pb-1 ps-4' style={{ fontSize: "18px", color: "#4a90a4", letterSpacing: "1px" }} > 8766779267 </h6></NavLink>
                                <NavLink className='text-decoration-none' to='mailto:info@soft.co.in'><h6 className='pb-2 ps-4' style={{ fontSize: "15px", color: "#747d7e", letterSpacing: "1px" }} >info@icloudsoft.com</h6></NavLink>
                            </div>
                        </div>

                        {/* <div className="col-lg-4">
                            <div className='p-4 FontFamilyMontSerret'>
                                <h6 className='pb-1 text-center' style={{ fontSize: "19px", color: "#212121" }} > Taxi Fare from Pune </h6>
                                <img className='w-100 ' src={require("../img/OutRate1.png")} alt="emp1" title='emp-1' />
                            </div>
                        </div> */}

                        {/* <div className="col-lg-4">
                            <div className='p-4 FontFamilyMontSerret'  >
                                <h6 className='pb-1 text-center' style={{ fontSize: "19px", color: "#212121" }} > Today's Offer </h6>
                                <img className='w-100 ' src={require("../img/OutRate2.png")} alt="emp1" title='emp-1' />
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default OutstationRate