import React, { useRef, useState } from 'react'
import "./Comman.css"
import { NavLink } from 'react-router-dom';
import Silder from './Silder';

const Home = () => {

  const [display, setDisplay] = useState("ONE WAY");

  const [btn1, setBtn1] = useState("newStyle");

  const [btn2, setBtn2] = useState("btnStyle");


  const changleDisplay = () => {
    setDisplay("ROUND TRIP")
    setBtn1("newStyle")
    setBtn2("btnStyle")
  }

  const changleDisplay2 = () => {
    setDisplay("ONE WAY")
    setBtn1("btnStyle")
    setBtn2("newStyle")
  }


  const form = useRef();

  const [Name, setName] = useState("");

  const [Name1, setName1] = useState("");

  const [Date, setDate] = useState("");

  const [Monumber, setMoNumber] = useState("");

  const [Name2, setName2] = useState("");

  const [Name3, setName3] = useState("");

  const [Date1, setDate1] = useState("");

  const [Date2, setDate2] = useState("");

  const [Monumber1, setMoNumber1] = useState("");

  const [errors, setErrors] = useState({});

  const loginHandle = (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      errors.Name = ""; errors.Name1 = ""; errors.Date = ""; errors.Monumber = "";

      const user = {
        Name: Name,
        Name1: Name1,
        Date: Date,
        Monumber: Monumber,
      }
      console.log(user);


      var url = "https://wa.me/9527468898?text="
        + "NAME:" + Name + "NAME1:" + Name1 + "NAME2:" + Name2 + "MONUMBER:" + Monumber;

      window.open(url, '_blank').focus();
    }

    else {
      setErrors(errors);
    }

  }

  const validateForm = () => {
    const errors = {};

    if (!Name) {
      errors.Name = "Names Is Requried";
    }
    else if (Name.length < 2) {
      errors.Name = "Names Requried At Least Two Characters"
    }

    if (!Name1) {
      errors.Name1 = "Names Is Requried";
    }
    else if (Name1.length < 2) {
      errors.Name1 = "Names Requried At Least Two Characters"
    }



    if (!Monumber) {
      errors.Monumber = "Mobile Number Is Requried";
    }
    else if (Monumber.length !== 10) {
      errors.Monumber = "Mobile Number Must Have 10 Digit";
    }

    return errors;
  }




  const loginHandle1 = (e) => {
    e.preventDefault();

    const errors = validateForm1();

    if (Object.keys(errors).length === 0) {
      errors.Name2 = ""; errors.Name3 = ""; errors.Date1 = ""; errors.Date2 = ""; errors.Monumber1 = "";

      const user1 = {
        Name2: Name2,
        Name3: Name3,
        Date1: Date1,
        Date2: Date2,
        Monumber1: Monumber1
      }
      console.log(user1);


      // }

      var url = "https://wa.me/9527468898?text="
        + "NAME2:" + Name2 + "NAME3:" + Name3 + "MONUMBER1:" + Monumber1 + "DATE1:" + Date1 + "DATE2:" + Date2;

      window.open(url, '_blank').focus();
    }


    else {
      setErrors(errors);
    }

  }

  const validateForm1 = () => {
    const errors = {};

    if (!Name2) {
      errors.Name2 = "Names Is Requried";
    }
    else if (Name2.length < 2) {
      errors.Name2 = "Names Requried At Least Two Characters"
    }

    if (!Name3) {
      errors.Name3 = "Names Is Requried";
    }
    else if (Name3.length < 2) {
      errors.Name3 = "Names Requried At Least Two Characters"
    }

    if (!Monumber1) {
      errors.Monumber1 = "Mobile Number Is Requried";
    }
    else if (Monumber1.length !== 10) {
      errors.Monumber1 = "Mobile Number Must Have 10 Digit";
    }

    return errors;
  }

  return (

    <>

      <section>
        <div className="container-fluid p-lg-0" style={{ backgroundImage: `url(${require("../img/Homebg2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: ' center,center ', height: "100%" }}>
          <section>
            <div className="container-fluid  d-none d-lg-block d-block " style={{ backgroundColor: "#295063" }} >
              <div className="container"  >
                <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                  <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                    <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                  </li>
                  <li className=" d-flex justify-content-lg-center ps-lg-5" >
                    <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                  </li>
                  <li className=" d-flex justify-content-lg-center ps-lg-5" >
                    <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="container-fluid d-lg-none d-none " style={{ backgroundColor: "#295063" }} >
              <div className="container"  >
                <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                  <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                    <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                  </li>
                  <li className=" d-flex justify-content-lg-center ps-lg-5" >
                    <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                  </li>
                  <li className=" d-flex justify-content-lg-center ps-lg-5" >
                    <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <div className="p-3">
            <div className="row  d-flex justify-content-center"  >
              <div className="col-lg-7 d-none d-lg-block d-block text-center">
                <div className='ps-lg-5 ms-lg-5'>
                  <h3 className='HomeFontRALEWAY ps-lg-5 ms-lg-5' style={{ fontSize: "42px", color: "#fff", fontWeight: "800" }} >Book Your Tour With iCloud Cab</h3>
                  <h5 className='HomeFontEXCULSIVEPUNERATE p-2 ps-lg-5 ms-lg-5' style={{ backgroundColor: "#007cb9", fontSize: "24px", color: "#ffeb3b", fontWeight: "800", lineHeight: "30px", textShadow: "0 1px 5px #000", letterSpacing: "2px" }} >Exclusive Pune Rates  </h5>
                  <h6 className='HomeFontEXCULSIVEPUNERATE pb-3 ps-lg-5 ms-lg-5' style={{ fontStyle: "oblique", fontFamily: "inherit", fontSize: "17px", color: "#e6e6e6", lineHeight: "25px", letterSpacing: "2px" }} >Starting from @Rs.10 per KM</h6>
                </div>
                <div>
                  <img className='pe-lg-5 me-lg-5' style={{ width: "60%" }} src={require("../img/car.png")} alt="emp1" title='emp-1' />
                </div>
              </div>

              <div className="col-lg-7 d-lg-none d-none text-center">
                <div className='ps-lg-5 ms-lg-5'>
                  <h3 className='HomeFontRALEWAY ps-lg-5 ms-lg-5' style={{ fontSize: "42px", color: "#fff", fontWeight: "800" }} >Book Your Tour With iCloud Cab</h3>
                  <h5 className='HomeFontEXCULSIVEPUNERATE p-2 ps-lg-5 ms-lg-5' style={{ backgroundColor: "#007cb9", fontSize: "24px", color: "#ffeb3b", fontWeight: "800", lineHeight: "30px", textShadow: "0 1px 5px #000", letterSpacing: "2px" }} >Exclusive Pune Rates  </h5>
                  <h6 className='HomeFontEXCULSIVEPUNERATE pb-3 ps-lg-5 ms-lg-5' style={{ fontStyle: "oblique", fontFamily: "inherit", fontSize: "17px", color: "#e6e6e6", lineHeight: "25px", letterSpacing: "2px" }} >Starting from @Rs.10 per KM</h6>
                </div>
                <div>
                  <img className='pe-lg-5 me-lg-5' style={{ width: "60%" }} src={require("../img/car.png")} alt="emp1" title='emp-1' />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="container p-3 FORMWIDTH" style={{ backgroundColor: "#fff" }}>
                  <div className="row d-flex justify-content-center ">
                    <div className="row d-flex justify-content-lg-center pb-2" style={{ borderBottom: "1px solid #9e9e9e", width: "90%" }} >
                      <div className="col-lg-5 col-6  fontFamilyHeader1" style={{ fontSize: "12px" }} >
                        <button className={btn1} onClick={changleDisplay}> ROUND TRIP </button>
                      </div>

                      <div className="col-lg-5 col-6 d-flex  fontFamilyHeader1" style={{ fontSize: "12px" }} >
                        <button className={btn2} onClick={changleDisplay2}>ONE WAY</button>
                      </div>

                    </div>

                    <div >
                      {

                        display === "ONE WAY" ?
                          <div className="col-lg-12">
                            <div className='p-lg-2 FontFamilyMontSerret' style={{ backgroundColor: "#fff" }} >
                              <h6 className='text-center'> <i class="fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> <span className='ps-2' style={{ color: "#100e0e", fontSize: "16px" }} >Own Way Journey</span> </h6>
                              <form ref={form} onSubmit={loginHandle} className="row  g-1 " >
                                <div class="col-lg-12 ">
                                  <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Pickup from :</label>
                                  <input type="text" className="form-control  text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Pickup Location"
                                    onChange={e => setName(e.target.value)}
                                    value={Name} />
                                  {errors.Name && <div className='text-danger'>{errors.Name}</div>}
                                </div>

                                <div class="col-lg-12 ">
                                  <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Travelling to :</label>
                                  <input type="text" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                    onChange={e => setName1(e.target.value)}
                                    value={Name1} />
                                  {errors.Name1 && <div className='text-danger'>{errors.Name1}</div>}
                                </div>

                                <div className='col-lg-12'>
                                  <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > Travel Date :</label>
                                  <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder="" name="date"
                                    onChange={e => setDate(e.target.value)} value={Date} />
                                  {errors.Date && <div className='text-danger'>{errors.Date}</div>}
                                </div>

                                <div class="col-lg-12 ">
                                  <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Mobile no :</label>
                                  <input type="number" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Mobile Number"
                                    onChange={e => setMoNumber(e.target.value)}
                                    value={Monumber} />
                                  {errors.Monumber && <div className='text-danger'>{errors.Monumber}</div>}
                                </div>

                                <div className="col-lg-12 pt-2">
                                  <button type="submit" className=" p-1 w-100 " style={{ backgroundColor: "#000080", border: "1px solid #000080", color: "#fff", height: "40px", letterSpacing: "1px" }} ><b className='p-lg-5' style={{ fontWeight: "600" }} >Check Rates</b></button>
                                </div>
                              </form>
                            </div>
                          </div>

                          : display === "ROUND TRIP" ?
                            <div className="col-lg-12">
                              <div className='p-lg-2 FontFamilyMontSerret' style={{ backgroundColor: "#fff" }} >
                                <h6 className='text-center'> <i class="fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> <span className='ps-2' style={{ color: "#100e0e", fontSize: "16px" }} >Round the Trip Journey</span> </h6>
                                <form ref={form} onSubmit={loginHandle1} className="row g-1 " >
                                  <div class="col-lg-12 ">
                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Pickup from :</label>
                                    <input type="text" className="form-control  text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Pickup Location"
                                      onChange={e => setName2(e.target.value)}
                                      value={Name2} />
                                    {errors.Name2 && <div className='text-danger'>{errors.Name2}</div>}
                                  </div>

                                  <div class="col-lg-12 ">
                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Travelling to :</label>
                                    <input type="text" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                      onChange={e => setName3(e.target.value)}
                                      value={Name3} />
                                    {errors.Name3 && <div className='text-danger'>{errors.Name3}</div>}
                                  </div>

                                  <div className='col-lg-12'>
                                    <div className='row g-2'>
                                      <div className="col-lg-6">
                                        <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > From To :</label>
                                        <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder=""
                                          onChange={e => setDate1(e.target.value)} value={Date1} />
                                        {errors.Date1 && <div className='text-danger'>{errors.Date1}</div>}
                                      </div>

                                      <div className="col-lg-6">
                                        <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > To :</label>
                                        <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder=""
                                          onChange={e => setDate2(e.target.value)} value={Date2} />
                                        {errors.Date2 && <div className='text-danger'>{errors.Date2}</div>}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-12 ">
                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Mobile no :</label>
                                    <input type="number" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Mobile Number"
                                      onChange={e => setMoNumber1(e.target.value)}
                                      value={Monumber1} />
                                    {errors.Monumber1 && <div className='text-danger'>{errors.Monumber1}</div>}
                                  </div>

                                  <div className="col-lg-12 pt-2">
                                    <button type="submit" className=" p-1 w-100 " style={{ backgroundColor: "#000080", border: "1px solid #000080", color: "#fff", height: "40px", letterSpacing: "1px" }} ><b className='p-lg-5' style={{ fontWeight: "600" }} >Check Rates</b></button>
                                  </div>
                                </form>
                              </div>
                            </div>

                            : <div>
                              somethings went wrong
                            </div>
                      }
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>

      </section>

      <section>
        <div className="container">
          <div className='d-flex justify-content-center '>
            <div className='text-center p-3'  >
              <h4 className='' style={{ fontSize: "24px", fontWeight: "600", fontStyle: "italic", letterSpacing: "2px" }} >COMPLETE RANGE OF WELL MAINTAINED CARS</h4>
              <div className='d-flex justify-content-center p-3'>
                <div className='' style={{ borderTop: "1px solid #dcd7ca", width: "51%" }}>
                </div>
              </div>
              <div className='d-flex justify-content-center' >
                <h6 className='' style={{ fontSize: "15px", fontStyle: "italic", width: "90%", letterSpacing: "1px" }} > We have wide range of Vehicles for Outstation Tours on hire basis. All Vehicles are well Maintained with Experienced Drivers.</h6>
              </div>
            </div>
          </div>

          <div className="row p-lg-5 d-flex justify-content-center align-items-center">
            <div className="col-lg-3 col-md-6">
              <div className='pt-2 pb-2'>
                <img className='w-100 ' style={{ height: "188px" }} src={require("../img/CAR1.png")} alt="emp1" title='emp-1' />
                <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                  <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                  <div className="row d-flex justify-content-around">
                    <div className='col-lg-8 col-8'>
                      <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                      <div className='row'>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-4'>
                      <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                      <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 ">
              <div className='pt-2 pb-2'>
                <img className='w-100' style={{ height: "188px" }} src={require("../img/CAR2.png")} alt="emp1" title='emp-1' />
                <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                  <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                  <div className="row d-flex justify-content-around">
                    <div className='col-lg-8 col-8'>
                      <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                      <div className='row'>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-4'>
                      <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                      <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 ">
              <div className='pt-2 pb-2'>
                <img className='w-100' style={{ height: "188px" }} src={require("../img/CAR3.png")} alt="emp1" title='emp-1' />
                <div className='p-2 ' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                  <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                  <div className="row d-flex justify-content-around">
                    <div className='col-lg-8 col-8'>
                      <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                      <div className='row'>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-4'>
                      <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                      <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 ">
              <div className='pt-2 pb-2'>
                <img className='w-100' style={{ height: "187px" }} src={require("../img/CAR4.png")} alt="emp1" title='emp-1' />
                <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                  <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                  <div className="row d-flex justify-content-around ">
                    <div className='col-lg-8 col-8'>
                      <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                      <div className='row'>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                        </div>
                        <div className='col-lg-1 col-sm-1 col-1'>
                          <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-4'>
                      <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                      <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container pb-5">
          <div className='text-center'>
            <h6 className='' style={{ fontSize: "26px", color: "#212121", fontWeight: "600", fontStyle: "italic" }} >BENEFITS OF OUTSTATION TAXI WITH ICLOUD CAB</h6>
            <div className='d-flex justify-content-center p-2'>
              <div className='' style={{ borderTop: "1px solid #dcd7ca", width: "35%" }}>
              </div>
            </div>
            <div className='d-flex justify-content-center p-2' >
              <h6 className='' style={{ fontSize: "15px", fontStyle: "italic", letterSpacing: "1px" }} > Trusted Car Rental Service in Pune</h6>
            </div>
          </div>

          <div className="row p-lg-4">
            <div className="col-lg-12">
              <div className="row g-4 p-2 ">
                <div className="col-lg-4 ">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100' src={require("../img/diary.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} >Online Taxi Booking</h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} >Book your Taxi Online without any hassle and get confirmation on mobile.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100' src={require("../img/calendar.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} > Clean Cars </h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} > We have wide range of fleets which are perfect for Outstation Tours. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100 ' src={require("../img/clock.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} > On Time Service </h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} > We have a system to maintain discipline for timely pickups. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-4 p-2">
                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100 ' src={require("../img/photos.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} > Client Satisfaction </h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} > We have many clents, who are satisfied with our services & Rates. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100 ' src={require("../img/graph.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} > Well Trained Drivers </h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} > All our Drivers are verified & well Trained for Driver on Highway & Hills. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100' src={require("../img/diary.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} >Online Taxi Booking</h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} >Book your Taxi Online without any hassle and get confirmation on mobile.</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row g-4 p-2">
                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100 ' src={require("../img/clock.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} > On Time Service </h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} > We have a system to maintain discipline for timely pickups. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100 ' src={require("../img/box.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} > Carriers on Top </h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} > All our Vehicles are equipped with carriers on top for extra luggage. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row d-flex justify-content-center align-items-center pt-2">
                    <div className="col-lg-3  col-3">
                      <div>
                        <img className='w-100' src={require("../img/calendar.png")} alt="emp1" title='emp-1' />
                      </div>
                    </div>
                    <div className="col-lg-9  col-9">
                      <div>
                        <h6 className='' style={{ fontSize: "16px", fontWeight: "700", color: "#008477", fontStyle: "italic" }} > Clean Cars </h6>
                        <p className='HomeFontRALEWAYLIGHT' style={{ fontSize: "12px", color: "#100e0e", lineHeight: "25px" }} > We have wide range of fleets which are perfect for Outstation Tours. </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>



          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className='d-flex justify-content-center '>
            <div className='text-center'  >
              <h4 className='' style={{ fontSize: "24px", fontWeight: "600", fontStyle: "italic", letterSpacing: "2px" }} >TOP DESTINATIONS</h4>
              <div className='d-flex justify-content-center p-3'>
                <div className='' style={{ borderTop: "1px solid #dcd7ca", width: "51%" }}>
                </div>
              </div>
              <div className='d-flex justify-content-center' >
                <h6 className='' style={{ fontSize: "15px", fontStyle: "italic", width: "90%", letterSpacing: "1px" }} > Enjoy your weekends with Our Smartly Designed, Affordable & the Most Popular Outstation Tour Packages. We covered almost all Tourist Destinations.</h6>
              </div>
            </div>
          </div>

          <div className="row p-lg-5 " >
            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/mumbai'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img1.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Mumbai Darshan </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/shirdi'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img2.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Shirdi Darshan </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/mahabaleshwar'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img3.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Mahabaleshwar Darshan </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/lonavala'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img4.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Lonavala Tour </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/mahalakshmi'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img5.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Shri Mahalaxmi Ambabai Temple </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/ashtvinayak'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img6.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Ashtavinayak Darshan </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/kolhapur'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img7.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Kolhapur Darshan Cab </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6">
              <NavLink className='text-decoration-none' to='/nagpur'>
                <div className='pt-2 pb-2'>
                  <img className='w-100 ' style={{ height: "188px" }} src={require("../img/img8.png")} alt="emp1" title='emp-1' />
                  <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                    <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#3f51b5", lineHeight: "1.5" }} > Nagpur Darshan </h6>
                    <div className="row d-flex justify-content-around">
                      <div className='col-lg-7 col-8'>
                        <div className='row'>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                          </div>
                          <div className='col-lg-1 col-sm-1 col-1'>
                            <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-4'>
                        <h6 className='' style={{ color: "#939393" }} > | <i class="ps-3 ms-3 text-center fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> </h6>
                        <h6 className='ps-2' style={{ color: "#939393", fontWeight: "600", fontSize: "12px" }}  >ALL INCLUSIVE</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </NavLink>
            </div>

            <div className='text-center p-lg-4 mt-3'>
              <h3 className='' style={{ fontSize: "26px", color: "#212121", fontWeight: "600", fontStyle: "italic" }} >DON'T WASTE TIME ! & BOOK YOUR TAXI NOW</h3>
              <p className='' style={{ fontSize: "16px", color: "#100e0e", fontStyle: "italic" }} >We have designed special outstation tour packages to fit the travel needs of everyone.</p>
              <div className='pt-3 pb-2'>
                <NavLink to='/contactus'> <button className='' style={{ backgroundColor: "#009688", border: "1px solid #009688", color: "white", padding: "8px 8px" }} ><b className='p-2' style={{ fontSize: "1rem", fontStyle: "italic" }}> Book Now </b></button> </NavLink>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* <section>
        <div className="container-fluid" style={{ backgroundColor: "#ECECEC" }} >
          <div className="container">
            <div className="row p-lg-5 ">
              <div className='text-center mt-2 mb-lg-3 pb-lg-3 p-2'>
                <h6 className='FontFamilyMontSerret' style={{ fontSize: "25px", color: "#212121", fontWeight: "600" }} >Most Popular Destinations</h6>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5 col-md-6">
                <div className='FontFamilyMontSerret' >
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Solapur Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Shirdi Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to kolhapur Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Hyderabad Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Kolhapur</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Sangli</p>
                </div>
              </div>

              <div className="col-lg-5 col-md-6">
                <div className='FontFamilyMontSerret' >
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Solapur Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Shirdi Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to kolhapur Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Pune to Hyderabad Taxi</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Kolhapur</p>
                  <p className='' style={{ color: "#2a6496", fontSize: "16px", lineHeight: "2" }} >Taxi Service in Sangli</p>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section> */}

      <Silder />

    </>

  )
}

export default Home