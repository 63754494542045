import React, { useRef, useState } from 'react'
import Silder from './Silder';
import { NavLink } from 'react-router-dom';

const Kolhapur = () => {

    const [display, setDisplay] = useState("ONE WAY");

    const [btn1, setBtn1] = useState("newStyle");

    const [btn2, setBtn2] = useState("btnStyle");


    const changleDisplay = () => {
        setDisplay("ROUND TRIP")
        setBtn1("newStyle")
        setBtn2("btnStyle")
    }

    const changleDisplay2 = () => {
        setDisplay("ONE WAY")
        setBtn1("btnStyle")
        setBtn2("newStyle")
    }


    const form = useRef();

    const [Name, setName] = useState("");

    const [Name1, setName1] = useState("");

    const [Date, setDate] = useState("");

    const [Monumber, setMoNumber] = useState("");

    const [Name2, setName2] = useState("");

    const [Name3, setName3] = useState("");

    const [Date1, setDate1] = useState("");

    const [Date2, setDate2] = useState("");

    const [Monumber1, setMoNumber1] = useState("");

    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.Name = ""; errors.Name1 = ""; errors.Date = ""; errors.Monumber = "";

            const user = {
                Name: Name,
                Name1: Name1,
                Date: Date,
                Monumber: Monumber,
            }
            console.log(user);

            var url = "https://wa.me/9527468898?text="
                + "NAME:" + Name + "NAME1:" + Name1 + "NAME2:" + Name2 + "MONUMBER:" + Monumber;

            window.open(url, '_blank').focus();
        }
        else {
            setErrors(errors);
        }

    }

    const validateForm = () => {
        const errors = {};

        if (!Name) {
            errors.Name = "Names Is Requried";
        }
        else if (Name.length < 2) {
            errors.Name = "Names Requried At Least Two Characters"
        }

        if (!Name1) {
            errors.Name1 = "Names Is Requried";
        }
        else if (Name1.length < 2) {
            errors.Name1 = "Names Requried At Least Two Characters"
        }



        if (!Monumber) {
            errors.Monumber = "Mobile Number Is Requried";
        }
        else if (Monumber.length !== 10) {
            errors.Monumber = "Mobile Number Must Have 10 Digit";
        }

        return errors;
    }

    const loginHandle1 = (e) => {
        e.preventDefault();

        const errors = validateForm1();

        if (Object.keys(errors).length === 0) {
            errors.Name2 = ""; errors.Name3 = ""; errors.Date1 = ""; errors.Date2 = ""; errors.Monumber1 = "";

            const user1 = {
                Name2: Name2,
                Name3: Name3,
                Date1: Date1,
                Date2: Date2,
                Monumber1: Monumber1
            }
            console.log(user1);

            var url = "https://wa.me/9527468898?text="
                + "NAME2:" + Name2 + "NAME3:" + Name3 + "MONUMBER1:" + Monumber1 + "DATE1:" + Date1 + "DATE2:" + Date2;

            window.open(url, '_blank').focus();
        }


        else {
            setErrors(errors);
        }

    }

    const validateForm1 = () => {
        const errors = {};

        if (!Name2) {
            errors.Name2 = "Names Is Requried";
        }
        else if (Name2.length < 2) {
            errors.Name2 = "Names Requried At Least Two Characters"
        }

        if (!Name3) {
            errors.Name3 = "Names Is Requried";
        }
        else if (Name3.length < 2) {
            errors.Name3 = "Names Requried At Least Two Characters"
        }

        if (!Monumber1) {
            errors.Monumber1 = "Mobile Number Is Requried";
        }
        else if (Monumber1.length !== 10) {
            errors.Monumber1 = "Mobile Number Must Have 10 Digit";
        }

        return errors;
    }


    return (

        <>

            <section>
                <div className="container-fluid  d-none d-lg-block d-block " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-none " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-3 pb-4">
                    <div className="row d-flex justify-content-center g-2 "  >

                        <div className="col-lg-4 mb-4">
                            <div className="container p-3 FORMWIDTH" style={{ backgroundColor: "#fff", boxShadow: "0 0 15px 1px rgba(0,0,0,.55)" }}>
                                <div className="row d-flex justify-content-center ">
                                    <div className="row d-flex justify-content-lg-center pb-2" style={{ borderBottom: "1px solid #9e9e9e", width: "90%" }} >
                                        <div className="col-lg-5 col-6  fontFamilyHeader1" style={{ fontSize: "12px" }} >
                                            <button className={btn1} onClick={changleDisplay}> ROUND TRIP </button>
                                        </div>

                                        <div className="col-lg-5 col-6 d-flex  fontFamilyHeader1" style={{ fontSize: "12px" }} >
                                            <button className={btn2} onClick={changleDisplay2}>ONE WAY</button>
                                        </div>

                                    </div>

                                    <div >
                                        {

                                            display === "ONE WAY" ?
                                                <div className="col-lg-12">
                                                    <div className='p-lg-2 FontFamilyMontSerret' style={{ backgroundColor: "#fff" }} >
                                                        <h6 className='text-center'> <i class="fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> <span className='ps-2' style={{ color: "#100e0e", fontSize: "16px" }} >Own Way Journey</span> </h6>
                                                        <form ref={form} onSubmit={loginHandle} className="row  g-1 " >
                                                            <div class="col-lg-12 ">
                                                                <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Pickup from :</label>
                                                                <input type="text" className="form-control  text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                                                    onChange={e => setName(e.target.value)}
                                                                    value={Name} />
                                                                {errors.Name && <div className='text-danger'>{errors.Name}</div>}
                                                            </div>

                                                            <div class="col-lg-12 ">
                                                                <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Travelling to :</label>
                                                                <input type="text" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                                                    onChange={e => setName1(e.target.value)}
                                                                    value={Name1} />
                                                                {errors.Name1 && <div className='text-danger'>{errors.Name1}</div>}
                                                            </div>

                                                            <div className='col-lg-12'>
                                                                <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > Travel Date :</label>
                                                                <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder="" name="date"
                                                                    onChange={e => setDate(e.target.value)} value={Date} />
                                                                {errors.Date && <div className='text-danger'>{errors.Date}</div>}
                                                            </div>

                                                            <div class="col-lg-12 ">
                                                                <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Mobile no :</label>
                                                                <input type="number" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Mobile Number"
                                                                    onChange={e => setMoNumber(e.target.value)}
                                                                    value={Monumber} />
                                                                {errors.Monumber && <div className='text-danger'>{errors.Monumber}</div>}
                                                            </div>

                                                            <div className="col-lg-12 pt-2">
                                                                <button type="submit" className=" p-1 w-100 " style={{ backgroundColor: "#000080", border: "1px solid #000080", color: "#fff", height: "40px", letterSpacing: "1px" }} ><b className='p-lg-5' style={{ fontWeight: "600" }} >Check Rates</b></button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                : display === "ROUND TRIP" ?
                                                    <div className="col-lg-12">
                                                        <div className='p-lg-2 FontFamilyMontSerret' style={{ backgroundColor: "#fff" }} >
                                                            <h6 className='text-center'> <i class="fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> <span className='ps-2' style={{ color: "#100e0e", fontSize: "16px" }} >Round the Trip Journey</span> </h6>
                                                            <form ref={form} onSubmit={loginHandle1} className="row g-1 " >
                                                                <div class="col-lg-12 ">
                                                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Pickup from :</label>
                                                                    <input type="text" className="form-control  text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                                                        onChange={e => setName2(e.target.value)}
                                                                        value={Name2} />
                                                                    {errors.Name2 && <div className='text-danger'>{errors.Name2}</div>}
                                                                </div>

                                                                <div class="col-lg-12 ">
                                                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Travelling to :</label>
                                                                    <input type="text" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                                                        onChange={e => setName3(e.target.value)}
                                                                        value={Name3} />
                                                                    {errors.Name3 && <div className='text-danger'>{errors.Name3}</div>}
                                                                </div>

                                                                <div className='col-lg-12'>
                                                                    <div className='row g-2'>
                                                                        <div className="col-lg-6">
                                                                            <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > From To :</label>
                                                                            <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder=""
                                                                                onChange={e => setDate1(e.target.value)} value={Date1} />
                                                                            {errors.Date1 && <div className='text-danger'>{errors.Date1}</div>}
                                                                        </div>

                                                                        <div className="col-lg-6">
                                                                            <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > To :</label>
                                                                            <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder=""
                                                                                onChange={e => setDate2(e.target.value)} value={Date2} />
                                                                            {errors.Date2 && <div className='text-danger'>{errors.Date2}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12 ">
                                                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Mobile no :</label>
                                                                    <input type="number" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Mobile Number"
                                                                        onChange={e => setMoNumber1(e.target.value)}
                                                                        value={Monumber1} />
                                                                    {errors.Monumber1 && <div className='text-danger'>{errors.Monumber1}</div>}
                                                                </div>

                                                                <div className="col-lg-12 pt-2">
                                                                    <button type="submit" className=" p-1 w-100 " style={{ backgroundColor: "#000080", border: "1px solid #000080", color: "#fff", height: "40px", letterSpacing: "1px" }} ><b className='p-lg-5' style={{ fontWeight: "600" }} >Check Rates</b></button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    : <div>
                                                        somethings went wrong
                                                    </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-7">
                            <div className='position-relative '>
                                <img className='w-100' style={{ height: "26rem" }} src={require("../img/img7.png")} alt="emp1" title='emp-1' />
                                <div className='mt-2 position-absolute' style={{ bottom: "3%", left: "4%" }} >
                                    <h6 className='' style={{ fontSize: "24px", color: "#fff", textTransform: "uppercase", fontWeight: "600", textShadow: "0 0 10px rgba(0,0,0,.5)" }} > Kolhapur Darshan  </h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section>
                <div className="container p-lg-5 pt-2">
                    <div className="row g-2 pb-4 ps-lg-5">
                        <div className="col-lg-4">
                            <div className='p-4 FontFamilyMontSerret' style={{ backgroundColor: "#fff", boxShadow: "0 0 10px 2px #3F51B5" }} >
                                <h6 className='pb-1' style={{ fontSize: "19px", color: "#212121" }} > Need Experts Help?</h6>
                                <h6 className='pb-2' style={{ fontSize: "12px", color: "#747d7e" }} > WE WOULD BE HAPPY TO HELP YOU!</h6>
                                <h6 className='pb-1 ps-4' style={{ fontSize: "18px", color: "#4a90a4", letterSpacing: "1px" }} > 9527468898 </h6>
                                <h6 className='pb-2 ps-4' style={{ fontSize: "15px", color: "#747d7e", letterSpacing: "1px" }} >info@icloudsoft.com</h6>
                            </div>
                        </div>

                        {/* <div className="col-lg-4">
                            <div className='p-4 FontFamilyMontSerret' style={{ backgroundColor: "#fff", boxShadow: "0 0 10px 2px #3F51B5" }} >
                                <h6 className='pb-1' style={{ fontSize: "19px", color: "#212121" }} > Need Experts Help?</h6>
                                <h6 className='pb-2' style={{ fontSize: "12px", color: "#747d7e" }} > WE WOULD BE HAPPY TO HELP YOU!</h6>
                                <h6 className='pb-1 ps-4' style={{ fontSize: "18px", color: "#4a90a4", letterSpacing: "1px" }} > 8766779267 </h6>
                                <h6 className='pb-2 ps-4' style={{ fontSize: "15px", color: "#747d7e", letterSpacing: "1px" }} >info@icloudsoft.com</h6>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default Kolhapur