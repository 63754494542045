import React, { useRef, useState } from 'react'

const Footer = () => {

    const form = useRef();

    const [number, setNumber] = useState("");

    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.number = "";
            const user = {
                number: number
            }
            console.log(user);


            // }

            var url = "https://wa.me/9527468898?text="
                + "NUMBER:" + number;

            window.open(url, '_blank').focus();

        }

        else {
            setErrors(errors);
        }

    }

    const validateForm = () => {
        const errors = {};

        if (!number) {
            errors.number = "The Field Is Requried";
        }
        else if (number.length !== 1 && number.length !==2) {
            errors.number = "Must Have 1 or more char";
        }

        return errors;
    }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#1C2224" }} >
                    <div className="row pt-4 p-lg-2 FontFamilyMontSerret">
                        <div className="col-lg-4">
                            <div className='p-2'>
                                <h4 className='text-white text-center pb-2' style={{ borderBottom: "1px solid #2a3436", fontSize: "17px", fontWeight: "600", letterSpacing: "1px" }} >About Taxi</h4>
                                <p className=' mt-lg-3' style={{ color: "#fff" }} > iCloud Cab is India Best No.1 Taxi Service, Provide Best Quality of Service like Tour Package and Taxi Booking, Car Rental Service We are provide online taxi  booking aggregator, providing customers with reliable and premium Intercity and Local car rental services. Over the last decade, we are uniquely placed as the largest chauffeur driven car rental company in India in terms of geographical reach.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 ">
                            <div className='p-lg-2'>
                                <h4 className='text-white text-center pb-2' style={{ borderBottom: "1px solid #2a3436", fontSize: "17px", fontWeight: "600", letterSpacing: "1px" }} >Get Free Call Back</h4>
                                <p className=' text-center mt-lg-3' style={{ fontSize: "13px", lineHeight: "24px", color: "#717576" }} > In case you need any assistance for Outstation Taxi needs, Kindly share your no, we will Call you back.</p>

                                <form ref={form} onSubmit={loginHandle} className="row ">

                                    <div className='col-lg-12'>
                                        <input type="number" className="form-control  text-dark " style={{ borderRadius: "0px 0px 0px 0px", border: "1px solid white", height: "45px" }} id="inputNumber" placeholder="1234567890" name="number" onChange={e => setNumber(e.target.value)} value={number} />
                                        {errors.number && <div className='text-danger'>{errors.number}</div>}
                                    </div>

                                    <div className="col-lg-12 pt-3 ">
                                        <button type="submit" className=" p-2 w-100 FontFamilyMontSerret" style={{ backgroundColor: "#FFC107", border: "1px solid #FFC107", color: "#fff", letterSpacing: "2px", height: "45px" }} ><b className='p-lg-5' style={{ fontWeight: "400" }} > GET FREE CALL BACK </b></button>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div className="col-lg-4 ">
                            <div className='p-lg-2'>
                                <h4 className='text-white text-center pb-2' style={{ borderBottom: "1px solid #2a3436", fontSize: "17px", fontWeight: "600", letterSpacing: "1px" }} >Contact us</h4>
                                <ul className='text-white mt-lg-3' style={{ listStyleType: "none" }} >
                                    <li className='mb-2' style={{ letterSpacing: "2px", color: "#9e9e9e", fontSize: "14px" }} > 1. <span className='ps-lg-4'>  Taxi Stand : </span></li>
                                    <li className='mb-2' style={{ letterSpacing: "2px", color: "#9e9e9e", fontSize: "14px" }} > 2.  <span className='ps-lg-4' > For Booking : +91 9527468898 </span></li>
                                    <li className='mb-2' style={{ letterSpacing: "2px", color: "#9e9e9e", fontSize: "14px" }} > 3.  <span className='ps-lg-4' > Customer care : +91 9527468898 </span></li>
                                    <li className='mb-2' style={{ letterSpacing: "2px", color: "#9e9e9e", fontSize: "14px" }}> 4.  <span className='ps-lg-4' > info@icloudsoft.com </span></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='text-lg-center p-1 text-white ' style={{ backgroundColor: "#0b0d0e", padding: "15px 0px" }}>
                    <h6 className='' style={{ fontSize: "15px", color: "#717576", fontWeight: "400" }} >Copyright © iCloudsoft Technology 2023,Inc. All Rights Reserved. Various trademarks held by their respective owners.</h6>
                </div>
            </section>
        </>
    )
}

export default Footer