import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Header.css"

const Header = () => {
    return (
        <>

            <nav id="navbar_top" className="navbar  navbar-expand-lg m-0 p-0" >
                <div className="container p-2 ">
                    <div className='ms-lg-4 ps-lg-5'>
                        <NavLink to="/">
                            <img className='w-100' style={{ height: "2.7rem" }} src={require("../img/logo2.png")} alt="icloudsoft_logo" />
                        </NavLink>
                    </div>
                    <button className="navbar-toggler me-3 p-2" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse   " id="navbarNavDropdown">
                        <div className='d-flex justify-content-lg-end me-lg-5 w-100  '>
                            <ul className="navbar-nav  d-flex justify-content-lg-end  w-100  " >

                                <li className='nav-item ps-lg-1 pe-lg-4 '>
                                    <NavLink className=" text-decoration-none  " style={{ color: "#295063", fontSize: "17px", fontWeight: "600" }} to="/" > Home </NavLink>
                                </li>
                                <li className='nav-item ps-lg-1 pe-lg-4' >
                                    <NavLink className=" text-decoration-none " style={{ color: "#295063", fontSize: "17px", fontWeight: "600" }} to="/outstationrate"> Outstation Rate </NavLink>
                                </li>
                                <li className='nav-item ps-lg-1 pe-lg-4'>
                                    <NavLink className=" text-decoration-none " style={{ color: "#295063", fontSize: "17px", fontWeight: "600" }} to="/contactus"  > Contact Us</NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </nav>

        </>
    )
}

export default Header